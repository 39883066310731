import React from 'react'
import get from 'lodash/get'
import arrowEmpty from '../assets/images/cursorThickerTopRight.svg'
import arrowFull from '../assets/images/cursorFilled.svg'
import Layout from '../layouts/layout'
import styles from './projects.module.scss'
import Media from '../components/media'
import Transitioner from '../components/transition'
import TransitionLink from 'gatsby-plugin-transition-link'
import Navigation from '../components/navigation'
import ProjectsList from '../components/projectsList'

class BlogIndex extends React.Component {
  constructor() {
    super()
    this.state = {
      mainPage: '',
    }
  }

  componentDidMount() {
    this.setState({
      mainPage: false,
    })
    window.addEventListener('mousemove', this.cursorImage)
  }

  componentWillUnmount() {
    this.setState({
      mainPage: true,
    })
    window.removeEventListener('mousemove', this.cursorImage)
  }

  handleHover(id, display) {
    const pic = document.getElementById(id)
    pic.style.display = display
  }

  cursorImage = e => {
    const cursor = document.getElementById('cursorContainer')
    const x = e.clientX
    const y = e.clientY

    cursor.style.transform = `translate(${x - cursor.clientWidth / 2}px, ${y -
      cursor.clientHeight / 2}px)`
  }

  render() {
    
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    
    return (
      <div className="background">
        <Transitioner>
          <Layout>
            <Navigation mainPage={this.state.mainPage} />

            <div className={styles.headerContainer}>
              <h1>Projects</h1>
            </div>

            <ProjectsList posts={posts} handleHover={this.handleHover} />
          </Layout>

          <ul className={styles.cursorContainer} id="cursorContainer">
            {posts.map(({ node }) => {
              return (
                <li key={node.slug}>
                  <div
                    id={`${node.slug}-preview`}
                    className={`previewContainer ${styles.projectPreview}`}
                  >
                    <div className={styles.imagePreview}>
                      <Media
                        videoCheck={node.heroImage.file.url.slice(-3)}
                        videoSrcURL={node.heroImage.file.url}
                        styleClass={node.heroImage.title}
                        imgSizes={node.heroImage.sizes}
                      />
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </Transitioner>
      </div>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage: heroImage {
            sizes(maxWidth: 1000, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
            }
            file {
              contentType
              url
            }
            title
          }
          shortDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
