import React from 'react'
import arrowEmpty from '../assets/images/cursorThickerTopRight.svg'
import arrowFull from '../assets/images/cursorFilled.svg'
import styles from '../pages/projects.module.scss'
import TransitionLink from 'gatsby-plugin-transition-link'
import { useSpring, animated } from 'react-spring'

const ProjectsList = ({ posts, handleHover }) => {
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]

  const trans1 = (x, y) => `translate3d(0,${(y / 3) * -1}px,0)`

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  return (
    <div
      className={styles.projectListContainer}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.ul
        className={styles.projectList}
        style={{ transform: props.xy.interpolate(trans1) }}
      >
        {posts.map(({ node }) => {
          return (
            <li key={node.slug}>
              <TransitionLink
                id={`${node.slug}-link`}
                className={`${styles.projectLink}`}
                to={`/project/${node.slug}/`}
                exit={{ length: 0.6 }}
                entry={{ delay: 0.8, length: 1 }}
                onMouseEnter={() =>
                  handleHover(`${node.slug}-preview`, 'block')
                }
                onMouseLeave={() => handleHover(`${node.slug}-preview`, 'none')}
              >
                <div className={`${styles.textContainer}`}>
                  <h2>{node.title}</h2>
                </div>

                <div className={styles.arrowContainer}>
                  <img src={arrowEmpty} alt="arrow empty" />
                </div>

                <div className={styles.arrowContainerFull}>
                  <img src={arrowFull} alt="arrow full" />
                </div>
              </TransitionLink>
            </li>
          )
        })}
      </animated.ul>
    </div>
  )
}

export default ProjectsList
